import {getUserInfo as adminGetUserInfo} from "@/api/hadmin.js";
import {getUserInfo} from "@/api/house.js";
import Cache from '@/utils/cache';

const cachePrefix = process.env.VUE_APP_AUTH_CACHE_PREFIX;

const state = {
	token: Cache.get(process.env.VUE_APP_LOGIN_STATUS, cachePrefix) || '',
	userInfo: Cache.get(process.env.VUE_APP_USER_INFO, cachePrefix),
	traceId: Cache.get("traceId", cachePrefix) || '',
};

const mutations = {
	LOGIN(state, opt) {
		state.token = opt.token;
		Cache.set(process.env.VUE_APP_LOGIN_STATUS, opt.token, 0, cachePrefix);
	},
	SETUID(state,val){
		state.uid = val;
		Cache.set(process.env.VUE_APP_UID, val, 0, cachePrefix);
	},
	UPDATE_LOGIN(state, token) {
		state.token = token;
	},
	LOGOUT(state) {
		state.token = undefined;
		Cache.clear(process.env.VUE_APP_LOGIN_STATUS, cachePrefix);
		Cache.clear(process.env.VUE_APP_UID, cachePrefix);
		Cache.clear(process.env.VUE_APP_USER_INFO, cachePrefix);
		state.userInfo = null;
	},
	UPDATE_USERINFO(state, userInfo) {
		state.userInfo = userInfo;
		Cache.set(process.env.VUE_APP_USER_INFO, userInfo, 0, cachePrefix);
	},
	SAVE_TRACE_ID(state, traceId) {
		if (state.traceId !== traceId) {
			Cache.set("traceId", traceId, 0, cachePrefix);
		}
	}
};

const actions = {
	USERINFO({
		state,
		commit
	}, force) {
		return new Promise(reslove => {
			getUserInfo().then(res => {
				commit("UPDATE_USERINFO", res);
				reslove(res);
			});
		}).catch(() => {

		});
	},
	ADMIN_USERINFO({
		state,
		commit
	}, force) {
		return new Promise(reslove => {
			adminGetUserInfo().then(res => {
				commit("UPDATE_USERINFO", res);
				reslove(res);
			});
		}).catch(() => {

		});
	}
};

export default {
	state,
	mutations,
	actions
};
