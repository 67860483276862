
import Vue from 'vue'

const locales = {keys(){return []}}
global['________'] = true;
delete global['________'];
global.__uniConfig = {"easycom":{"^u-(.*)":"uview-ui/components/u-$1/u-$1.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^uniad$":"@dcloudio/uni-cli-shared/components/uniad.vue","^ad-rewarded-video$":"@dcloudio/uni-cli-shared/components/ad-rewarded-video.vue","^ad-fullscreen-video$":"@dcloudio/uni-cli-shared/components/ad-fullscreen-video.vue","^ad-interstitial$":"@dcloudio/uni-cli-shared/components/ad-interstitial.vue","^ad-interactive$":"@dcloudio/uni-cli-shared/components/ad-interactive.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"},"condition":{"current":0,"list":[{"name":"","path":"","query":""}]},"globalStyle":{"navigationBarTextStyle":"black","navigationBarTitleText":"NoCode","navigationBarBackgroundColor":"#ffffff","backgroundColor":"#f3f5f9","pullToRefresh":{"color":"#00c8c8"}},"tabBar":{"color":"#8a8a8a","selectedColor":"#00c8c8","borderStyle":"black","backgroundColor":"#ffffff","list":[{"pagePath":"pages/home/index","iconPath":"/static/icon/home.png","selectedIconPath":"/static/icon/homeSelected.png","text":"首页","redDot":false,"badge":""},{"pagePath":"pages/user/index","iconPath":"/static/icon/user.png","selectedIconPath":"/static/icon/userSelected.png","text":"我的","redDot":false,"badge":""}]}};
global.__uniConfig.compilerVersion = '4.15';
global.__uniConfig.darkmode = false;
global.__uniConfig.themeConfig = {};
global.__uniConfig.uniPlatform = 'h5';
global.__uniConfig.appId = '';
global.__uniConfig.appName = '蚂蚁智管';
global.__uniConfig.appVersion = '1.0.1';
global.__uniConfig.appVersionCode = '101';
global.__uniConfig.router = {"mode":"hash","base":"./"};
global.__uniConfig.publicPath = "./";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":3000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {};
global.__uniConfig.qqMapKey = undefined;
global.__uniConfig.googleMapKey = undefined;
global.__uniConfig.aMapKey = undefined;
global.__uniConfig.aMapSecurityJsCode = undefined;
global.__uniConfig.aMapServiceHost = undefined;
global.__uniConfig.locale = "";
global.__uniConfig.fallbackLocale = "zh-Hans";
global.__uniConfig.locales = locales.keys().reduce((res,key)=>{const locale=key.replace(/\.\/(uni-app.)?(.*).json/,'$2');const messages = locales(key);Object.assign(res[locale]||(res[locale]={}),messages.common||messages);return res},{});
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-home-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/home/index"+'.vue')), 'pages-home-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/user/index"+'.vue')), 'pages-user-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-contactUs', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/user/contactUs"+'.vue')), 'pages-user-contactUs'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-opinion', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/user/opinion"+'.vue')), 'pages-user-opinion'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bill-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/bill/index"+'.vue')), 'pages-bill-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bill-details', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/bill/details"+'.vue')), 'pages-bill-details'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-session-login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/session/login"+'.vue')), 'pages-session-login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-join-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/join/index"+'.vue')), 'pages-join-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-join-details', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/join/details"+'.vue')), 'pages-join-details'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-suite-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/suite/index"+'.vue')), 'pages-suite-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-suite-details', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xl/Documents/wordspace/local/noCode/house/application/house/pages/suite/details"+'.vue')), 'pages-suite-details'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/home/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,
isTabBar:true,

          
          tabBarIndex:0
        },__uniConfig.globalStyle,{"navigationBarTitleText":"蚂蚁智管","navigationStyle":"custom"})
      },
      [
        createElement('pages-home-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-home-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/home/index',
isQuit:true,
isEntry:true,
isTabBar:true,
tabBarIndex:0,
  windowTop:0
}
},
{
path: '/pages/user/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:1
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的","navigationStyle":"custom"})
      },
      [
        createElement('pages-user-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:2,
  name:'pages-user-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/index',
isQuit:true,
isTabBar:true,
tabBarIndex:1,
  windowTop:0
}
},
{
path: '/pages/user/contactUs',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"联系我们"})
      },
      [
        createElement('pages-user-contactUs', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-contactUs',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/contactUs',
  windowTop:44
}
},
{
path: '/pages/user/opinion',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"意见反馈"})
      },
      [
        createElement('pages-user-opinion', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-opinion',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/opinion',
  windowTop:44
}
},
{
path: '/pages/bill/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"账单列表"})
      },
      [
        createElement('pages-bill-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bill-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bill/index',
  windowTop:44
}
},
{
path: '/pages/bill/details',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"账单详情"})
      },
      [
        createElement('pages-bill-details', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bill-details',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bill/details',
  windowTop:44
}
},
{
path: '/pages/session/login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"登录","navigationStyle":"custom"})
      },
      [
        createElement('pages-session-login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-session-login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/session/login',
  windowTop:0
}
},
{
path: '/pages/join/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"协议列表"})
      },
      [
        createElement('pages-join-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-join-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/join/index',
  windowTop:44
}
},
{
path: '/pages/join/details',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"协议详情"})
      },
      [
        createElement('pages-join-details', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-join-details',
  isNVue:false,maxWidth:0,
  pagePath:'pages/join/details',
  windowTop:44
}
},
{
path: '/pages/suite/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"房间列表"})
      },
      [
        createElement('pages-suite-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-suite-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/suite/index',
  windowTop:44
}
},
{
path: '/pages/suite/details',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"房间详情","navigationStyle":"custom"})
      },
      [
        createElement('pages-suite-details', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-suite-details',
  isNVue:false,maxWidth:0,
  pagePath:'pages/suite/details',
  windowTop:0
}
},
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
