<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
export default {
  onLaunch: function(options) {
    this.globalData.onLaunchData = options;
    let that = this;
    // #ifdef H5
    uni.getSystemInfo({
      success: function(res) {
        // 首页没有title获取的整个页面的高度，里面的页面有原生标题要减掉就是视口的高度
        // 状态栏是动态的可以拿到 标题栏是固定写死的是44px
        let height = res.windowHeight - res.statusBarHeight - 44
        // #ifdef H5
        that.globalData.windowHeight = res.windowHeight + 'px'
        // #endif

      }
    });
    // #endif
    // 获取导航高度；
    uni.getSystemInfo({
      success: function(res) {
        that.globalData.navHeight = res.statusBarHeight * (750 / res.windowWidth) + 91;
      }
    });
    // #ifdef MP
    let menuButtonInfo = uni.getMenuButtonBoundingClientRect();
    that.globalData.navH = menuButtonInfo.top * 2 + menuButtonInfo.height / 2;
    // #endif
    // #ifndef H5
    // if ("development" === process.env.NODE_ENV) {
    //   // 这里的代码会在应用启动或从后台进入前台时执行
    //   // 使用uni.navigateTo跳转到指定页面
    //   uni.navigateTo({
    //     url: '/pages/suite/details?id=562873128742981' // 替换为你的目标页面路径
    //   });
    // }
    // #endif
  },
  onShow: function(options) {
    this.globalData.onShowData = options;
    // #ifdef H5
    uni.getSystemInfo({
      success(e) {
        /* 窗口宽度大于420px且不在PC页面且不在移动设备时跳转至 PC.html 页面 */
        if (e.windowWidth > 420 && !window.top.isPC && !/iOS|Android/i.test(e.system)) {
          // window.location.pathname = 'https://java.crmeb.net/';
          /* 若你的项目未设置根目录（默认为 / 时），则使用下方代码 */
          window.location.pathname = '/static/html/pc.html';
        }
      }
    })
    // #endif
  },
  onHide: function() {
    console.log('App Hide')
  }
}
</script>

<style lang="scss">
/* 注意要写在第一行，同时给style标签加入lang="scss"属性 */
@import "uview-ui/index.scss";
@import 'static/css/base.scss';

</style>