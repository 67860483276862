import {toLogin} from '../libs/login';
import Cache from "@/utils/cache.js";
import store from '@/store';
import Vue from "vue";
import {head} from "@dcloudio/vue-cli-plugin-uni/packages/postcss/tags";

const cachePrefix = process.env.VUE_APP_REQUEST_CACHE_PREFIX;

let baseUrl = process.env.VUE_APP_BASE_URL;










/**
 * 发送请求
 *
 * @param method 发送方式
 * @param url 发送地址
 * @param data 发送数据
 * @param loading loading
 * @param cacheSecond 缓存秒数
 * @returns {Promise<unknown>}
 */
function service(method, url, data, loading, cacheSecond) {
    let header = baseHeader();
    header["Content-Type"] = "application/json;charset=utf-8"
    Vue.prototype.$log.debug("request Data", url, data)

    if (method === "POST" || method === "PUT") {
        header["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
        data = unidimensionalJson(data)
    }

    // get请求映射params参数
    if (method === 'GET' && data) {
        url = url + '?' + tansParams(data);
        url = url.slice(0, -1);
        data = {};
    }

    // 打开 loading
    if (loading) {
        uni.showLoading({title: '加载中', mask: true});
    }

    return new Promise((success, fail) => {
        if (cacheSecond && cacheSecond > 0) {
            const cacheData = Cache.get(url, cachePrefix);
            if (cacheData) {
                success(cacheData);
                return
            }
        }
        try {
            uni.request({
                url: baseUrl + url,
                method: method || 'GET',
                header: header,
                timeout: 120000, // 超时时间，单位 ms
                data: method === 'GET' ? void 0 : data,
                params: method === 'GET' ? data : void 0,
                success: (res) => {
                    Vue.prototype.$log.debug("response", res)
                    const statusCode = res.statusCode || 200;
                    const response = res.data;
                    const msg = response.msg || '系统错误';
                    if (200 !== statusCode) {
                        uni.hideLoading();
                        Vue.prototype.$util.waitTips(300, {title: msg});
                        return
                    }

                    store.commit('SAVE_TRACE_ID', response.traceId);
                    const code = response.code;

                    switch (code) {
                        case "SUCCESS":
                            Vue.prototype.$log.debug("response data", url, response.data)
                            success(response.data);
                            uni.hideLoading();
                            if (cacheSecond && cacheSecond > 0) {
                                Cache.set(url, response.data, cacheSecond, cachePrefix);
                            }
                            break
                        case "A0220":
                        case "A0242":
                        case "A0244":
                        case "A0243":
                            toLogin();
                            break
                        case "A0230":
                        case "D0101":
                            uni.hideLoading();
                            fail(response);
                            break
                        default:
                            uni.hideLoading();
                            fail(response);
                            Vue.prototype.$util.waitTips(300, {title: msg});
                            break
                    }
                },
                fail: (err) => {
                    Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
                    Vue.prototype.$log.error('Request failed', err);
                },
                complete: (err) => {
                    uni.hideLoading();
                    // 无论请求成功或失败都会执行的回调函数
                    console.debug('Request complete');
                }
            });
        } catch (e) {
            uni.hideLoading();
            Vue.prototype.$log.error("response fails", e)
            if (e.data instanceof String) {
                Vue.prototype.$util.waitTips(300, {title: e.data});
            } else {
                Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
            }
        }
    });
}

export function fileUpload(url, file, loading) {
    const header = baseHeader();
    // 删除 Content-type 的设置
    Vue.prototype.$log.debug("request url", url)

    // 打开 loading
    if (loading) {
        uni.showLoading({title: '加载中', mask: true});
    }

    return new Promise((success, fail, data) => {
        try {
            uni.uploadFile({
                url: baseUrl + url,
                method: 'POST',
                header: header,
                filePath: file,
                name: 'file',
                formData: data || {},
                success: (res) => {
                    uni.hideLoading();
                    Vue.prototype.$log.debug("response", res)
                    const statusCode = res.statusCode || 200;
                    const response = JSON.parse(res.data);
                    const msg = response.msg || '系统错误';
                    if (200 !== statusCode) {
                        Vue.prototype.$util.waitTips(300, {title: msg});
                        return
                    }

                    store.commit('SAVE_TRACE_ID', response.traceId);

                    const code = response.code;
                    switch (code) {
                        case "SUCCESS":
                            Vue.prototype.$log.debug("response data", response.data)
                            success(response.data);
                            break
                        case "A0220":
                        case "A0242":
                        case "A0244":
                        case "A0243":
                            toLogin();
                            break
                        case "D0101":
                        case "A0230":
                            fail(response);
                            break
                        default:
                            Vue.prototype.$util.Tips({title: msg});
                            fail(response);
                            break
                    }
                },
                fail: (msg) => {
                    uni.hideLoading();
                    Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
                    Vue.prototype.$log.error('Request failed', msg);
                }
            })
        } catch (e) {
            uni.hideLoading();
            Vue.prototype.$log.error("response fails", e)
            if (e.data instanceof String) {
                Vue.prototype.$util.waitTips(300, {title: e.data});
            } else {
                Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
            }
        }
    });
}


/**
 * 文件下载
 * @param method 请求方式
 * @param url 请求地址
 * @param data 参数
 * @param loading 是否需要 loading
 * @param openOrSave 打开或保存, true 打开; false 保存
 * @returns {Promise<unknown>}
 */
export function fileDown(method, url, data, loading, openOrSave) {
    const header = baseHeader();
    Vue.prototype.$log.debug("request url", url)

    if (method === "POST" || method === "PUT") {
        header["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
        data = unidimensionalJson(data)
    }

    // get请求映射params参数
    if (method === 'GET' && data) {
        url = url + '?' + tansParams(data);
        url = url.slice(0, -1);
        data = {};
    }

    // 打开 loading
    if (loading) {
        uni.showLoading({title: '加载中', mask: true});
    }

    return new Promise((success, fail) => {
        try {
            let fileType;
            uni.downloadFile({
                url: baseUrl + url,
                method: method || 'GET',
                header: header,
                data: method === 'GET' ? void 0 : data,
                params: method === 'GET' ? data : void 0,
                onHeadersReceived: function (res) {
                    const headers = res.headers;
                    const contentType = headers['content-type'] || headers['Content-Type'];
                    // 根据Content-Type确定fileType
                    if (contentType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                        fileType = 'xlsx';
                    } else if (contentType.startsWith('application/vnd.ms-excel')) {
                        fileType = 'xls';
                    } else if (contentType.startsWith('application/pdf')) {
                        fileType = 'pdf';
                    } else {
                        // 处理未知或不支持的文件类型
                        throw new Error('Unsupported Content-Type: ' + contentType);
                    }
                },
                success: (res) => {
                    const statusCode = res.statusCode || 200;
                    if (200 !== statusCode) {
                        uni.hideLoading();
                        Vue.prototype.$util.waitTips(300, {title: '文件下载失败'});
                        return
                    }
                    if(openOrSave) {
                        uni.openDocument({
                            filePath: res.tempFilePath,
                            showMenu: true,
                            fileType,
                            success: function () {
                                success();
                            },
                            fail: function (err) {
                                Vue.prototype.$util.waitTips(300, {title: '文件打开失败'});
                                Vue.prototype.$log.error('文件打开失败', err);
                            }
                        });
                    } else {
                        uni.saveFile({
                            tempFilePath: res.tempFilePath,
                            success: (result) => {
                                const savedFilePath = res.savedFilePath;
                                success(savedFilePath);
                            },
                            fail: (err) => {
                                Vue.prototype.$util.waitTips(300, {title: '保存文件失败'});
                                Vue.prototype.$log.error('保存文件失败', err);
                            }
                        });
                    }
                },
                fail: (err) => {
                    Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
                    Vue.prototype.$log.error('Request failed', err);
                },
                complete: (err) => {
                    uni.hideLoading();
                    // 无论请求成功或失败都会执行的回调函数
                    console.debug('Request complete');
                }
            });
        } catch (e) {
            uni.hideLoading();
            Vue.prototype.$log.error("response fails", e)
            if (e.data instanceof String) {
                Vue.prototype.$util.waitTips(300, {title: e.data});
            } else {
                Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
            }
        }
    });
}

/**
 *  复杂json转为一维的
 * @param data 数据值
 * @param form FormData实例
 * @param keyPrefix key 前缀
 * @param keySuffix key 后缀
 * @returns {*|FormData}
 * @constructor
 使用：unidimensionalJson("", a, null);
 */
function unidimensionalJson(data, form, keyPrefix, keySuffix) {
    if (data === undefined) {
        return form;
    }
    const dateKeys = Object.keys(data);
    if (dateKeys.length === 0) {
        return form;
    }
    if (keyPrefix === undefined) {
        keyPrefix = '';
    }
    if (keySuffix === undefined) {
        keySuffix = '';
    }
    form = form || {}
    dateKeys.forEach((key) => {
        let val = data[key]
        if (val === undefined) {
        } else if (val instanceof Array) { // 数组
            let pk = keyPrefix ? `${keyPrefix}${key}${keySuffix}[` : `${key}${keySuffix}[`
            unidimensionalJson(val, form, pk, ']')
        } else if (val instanceof Object) { // 数组
            let pk = keyPrefix ? `${keyPrefix}${key}${keySuffix}.` : `${key}${keySuffix}.`
            unidimensionalJson(val, form, pk)
        } else {
            form[`${keyPrefix}${key}${keySuffix}`] = val
        }
    });
    return form
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && value !== "" && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
                        let params = propName + '.' + key;
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}

/**
 * get 请求操作
 * @param url 路径
 * @param params 参数
 * @param loading 参数
 * @param cacheSecond 缓存的秒数
 * @returns {AxiosPromise}
 */
export function get(url, params, loading, cacheSecond) {
    return service('GET', url, params, loading || false, cacheSecond)
}

/**
 * post 请求操作
 * @param url 路径
 * @param data 参数
 * @param loading 参数
 * @param cacheSecond 缓存的秒数
 * @returns {AxiosPromise}
 */
export function post(url, data, loading, cacheSecond) {
    return service('POST', url, data, loading || false, cacheSecond)
}

/**
 * delete 请求操作
 * @param url 路径
 * @param loading 参数
 * @param cacheSecond 缓存的秒数
 * @returns {AxiosPromise}
 */
export function del(url, loading, cacheSecond) {
    return service('DELETE', url, {}, loading || false, cacheSecond)
}

/**
 * delete 请求操作
 * @param url 路径
 * @param data 参数
 * @param loading 参数
 * @param cacheSecond 缓存的秒数
 * @param isIntactUrl 是完整路径
 * @returns {AxiosPromise}
 */
export function put(url, data, loading, cacheSecond) {
    return service('PUT', url, data, loading || false, cacheSecond)
}

/**
 * 头部数据
 * @returns {{Project: string, Version: string, Driver: string, TraceId: *, "Content-Type": string}}
 */
export function baseHeader() {
    let header = {
        'Project': process.env.VUE_APP_PROJECT_ID,
        'Version': process.env.VUE_APP_VERSION,
        'Driver': Vue.prototype.$util.getDriver(),
        'TraceId': store.state.app.traceId,
    }
    if (store.state.app.token) header["Authorization"] = 'Bearer ' + store.state.app.token;
    return header;
}