// CacheUtil.js

class Cache {
    // 存储键值对到本地缓存，可以设置过期时间（单位：秒）
    static set(key, value, expireSeconds, prefix = 'default') {
        const expireTime = expireSeconds ? Date.now() + expireSeconds * 1000 : 0;
        const data = {
            value,
            expireTime
        };
        uni.setStorageSync(`${prefix}_${key}`, JSON.stringify(data));
    }

    // 从本地缓存中获取指定key的值，如果数据已过期则返回 null
    static get(key, prefix = 'default') {
        const dataStr = uni.getStorageSync(`${prefix}_${key}`);
        if (!dataStr) return null; // 没有缓存数据

        try {
            const data = JSON.parse(dataStr);
            if (data && data.expireTime && data.expireTime > 0 && data.expireTime < Date.now()) {
                // 数据已过期，删除该数据并返回 null
                this.clear(key, prefix);
                return null;
            }
            return data.value;
        } catch (error) {
            console.error("Error parsing cached data:", error);
            return null;
        }
    }

    // 从本地缓存中移除指定key的值
    static clear(key, prefix = 'default') {
        uni.removeStorageSync(`${prefix}_${key}`);
    }

    // 清空指定前缀的本地缓存
    static clearPrefix(prefix = 'default') {
        const keys = uni.getStorageInfoSync().keys;
        keys.forEach((key) => {
            if (key.startsWith(`${prefix}_`)) {
                uni.removeStorageSync(key);
            }
        });
    }

    // 清空所有本地缓存
    static clearAll() {
        uni.clearStorageSync();
    }

    // 检查本地缓存中是否存在指定key的值，并且未过期
    static has(key, prefix = 'default') {
        const dataStr = uni.getStorageSync(`${prefix}_${key}`);
        if (!dataStr) return false; // 没有缓存数据

        try {
            const data = JSON.parse(dataStr);
            return !!data && (!data.expireTime || data.expireTime >= Date.now());
        } catch (error) {
            console.error("Error parsing cached data:", error);
            return false;
        }
    }
}
export default Cache;
