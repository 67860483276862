import Vue from 'vue';
import VueLogger from 'vuejs-logger';

const isProduction = process.env.NODE_ENV === 'production';

const options = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
};

class LogService {
    constructor() {
        if (!LogService.instance) {
            this.logger = VueLogger;
            Vue.use(this.logger, options);
            this.currentLogLevel = options.logLevel;
            LogService.instance = this;
        }

        return LogService.instance;
    }

    shouldLog(level) {
        const levels = ['debug', 'info', 'warn', 'error', 'fatal'];
        const currentLevelIndex = levels.indexOf(this.currentLogLevel);
        const messageLevelIndex = levels.indexOf(level);
        return messageLevelIndex >= currentLevelIndex;
    }

    serializeArgs(args) {
        return args.map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : arg));
    }

    debug(...args) {
        if (this.shouldLog('debug')) {
            Vue.$log.debug(...this.serializeArgs(args));
        }
    }

    info(...args) {
        if (this.shouldLog('info')) {
            Vue.$log.info(...this.serializeArgs(args));
        }
    }

    warn(...args) {
        if (this.shouldLog('warn')) {
            Vue.$log.warn(...this.serializeArgs(args));
        }
    }

    error(...args) {
        if (this.shouldLog('error')) {
            Vue.$log.error(...this.serializeArgs(args));
        }
    }

    fatal(...args) {
        if (this.shouldLog('fatal')) {
            Vue.$log.fatal(...this.serializeArgs(args));
        }
    }

    setLogLevel(level) {
        this.currentLogLevel = level;
    }
}

const instance = new LogService();
Object.freeze(instance);

export default instance;
